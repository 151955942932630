import React from 'react';
import Routes from './Routes';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import configureStore from '../configureStore';
import Airbrake from './Airbrake';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  ApolloLink,
} from '@apollo/client';
import { ThemeProvider } from 'styled-components';
import { setContext } from '@apollo/client/link/context';
import cloneDeepWith from 'lodash/cloneDeepWith';
import { onError } from '@apollo/client/link/error';
import { signOutUser } from '../actions/AuthActions';

export const styledTheme = {
  lightblue: '#00AFEF',
  blue: '#278ADA',
  darkblue: '#0E2C44',
  midblue: '#1B4160',
  navyblue: '#071827',
  lightbluegrey: '#B4C2CE',
  bluegrey: '#7194AC',
  darkgrey: '#8FA9BE',
  meddarkgrey: '#C1CDD7',
  mediumgrey: '#CED8E3',
  medlightgrey: '#E0E5E9',
  lightgrey: '#E5E8E9',
  white: '#FFFFFF',
  red: '#CA3A3A',
  lightestCobalt: '#254a66',
  pink: '#D3347F',
  orange: '#FF886D',
  purple: '#966CB8',
  green: '#54C06E',
  cobalt: '#466B88',
  black: '#000000',
  lightGray: '#B4C2CE',
  darkGray: '#0E2C44',
  darkBlue: '#0e2b44',
  darkgreyHover: '#A6C0D4',
  darkblueHover: '#153A57',
  lightCobalt: '#5C80A2',
  navyblueHover: '#052135',
  navySecondary: '#1E3E58',
  orangeHover: '#FF9F89',
  purpleHover: '#7C688C',
  greenHover: '#6AE187',
  cobaltHover: '#5A82A2',
  focusPlaceholder: '#AAAAAA',
};

export const store = configureStore();

const muiTheme = getMuiTheme({
  fontFamily: 'Heebo, sans-serif',
  palette: {
    primary1Color: '#278ADA', // blue
    primary2Color: '#278ADA', // blue
    primary3Color: '#466B88', // cobalt
    accent1Color: '#8FA9BE', // darkgrey
    accent2Color: '#C1CDD7', // meddarkgrey
    accent3Color: '#E0E5E9', // medlightgrey
    textColor: '#0E2C44', // darkblue
    alternateTextColor: '#FFFFFF', // white
    canvasColor: '#FFFFFF', // white
    borderColor: '#FFFFFF00', // rgba(7,24,39,.85)
    disabledColor: '#C1CDD7', // meddarkgrey
    pickerHeaderColor: '#278ADA', // blue
    clockCircleColor: '#E0E5E9', // medlightgrey
    shadowColor: '#FFFFFF00', // rgba(7,24,39,.85)
  },
  overlay: {
    backgroundColor: 'rgba(7,24,39,.85)', // navyblue @ 85%
  },
  ripple: {
    color: '#FFFFFF00',
  },
});

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_DOMAIN}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      ...JSON.parse(localStorage.getItem('authTokenHeaders')),
      'location-id': localStorage.getItem('locationId'),
      'business-id': localStorage.getItem('businessId'),
    },
  };
});

const errorLink = onError(({ networkError }) => {
  if (networkError && networkError.statusCode === 401) {
    store.dispatch(signOutUser())
  }
});

const omitTypenameDeep = (variables) =>
  cloneDeepWith(variables, (value) => {
    if (value?.__typename) {
      const { __typename, ...valWithoutTypename } = value;
      return omitTypenameDeep(valWithoutTypename);
    }
    return undefined;
  });

const removeTypename = new ApolloLink((operation, forward) => {
  const newOperation = operation;
  newOperation.variables = omitTypenameDeep(newOperation.variables);
  return forward(newOperation);
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: errorLink.concat(removeTypename.concat(authLink.concat(httpLink))),
});

const Root = () => (
  <Airbrake>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <ThemeProvider theme={styledTheme}>
          <MuiThemeProvider muiTheme={muiTheme}>
            <IntlProvider locale='en'>
              <Routes store={store} />
            </IntlProvider>
          </MuiThemeProvider>
        </ThemeProvider>
      </Provider>
    </ApolloProvider>
  </Airbrake>
);

export default Root;
